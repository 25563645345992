<template>
  <div
    class="flex flex-col flex-1 overflow-hidden bg-white border border-gray-300 rounded"
  >
    <AvailableBalance class="mt-4" />

    <POrderGrid
      ref="ordergrid"
      transactionType="TO,HE,RO"
      :locationIdFrom="location.id"
      :cols="cols"
      :filters="filters"
      :orderBy="orderBy"
      :expandAll="expandAll"
      @click="onClick"
    ></POrderGrid>

    <portal to="actions">
      <div class="flex items-center ml-4 space-x-4">
        <PToggle
          v-model="expandAll"
          :stacked="true"
          :label="$tk('Common.General.Detailed')"
        />
      </div>
    </portal>

    <portal to="filters">
      <POrderFilters v-model="filters" />
    </portal>

    <EditDialog
      v-if="editDialogVisible"
      :showSorts="false"
      :showReal="true"
      :orderId="orderId"
      :tab="editDialogStartTab"
      @close="onClose"
      view="expedition"
    />
  </div>
</template>

<script>
import http from "@/http";
import { mapGetters } from "vuex";
import { format, add, sub } from "date-fns";
import EditDialog from "../../reception/dialogs/EditDialog.vue";
import AvailableBalance from "../components/AvailableBalance.vue";
import { getConfig } from "@/config";

const config = getConfig();

export default {
  components: {
    EditDialog,
    AvailableBalance,
  },

  props: {
    id: { type: String, required: false },
  },

  data() {
    return {
      cols:
        config?.theme === "swa"
          ? [
              {
                text: this.$tk("Common.Order.OrderNo"),
                value: "id",
                sortable: true,
                component: "p-display-text",
                width: 110,
                align: "center",
                classes: "font-medium",
              },
              {
                text: this.$tk("Common.Order.PickupDate"),
                value: "dateSend",
                sortable: true,
                component: "p-display-date",
                width: 110,
                align: "center",
              },
              {
                text: this.$tk("Common.Order.Cartman"),
                value: "cartmanName",
                sortable: true,
                component: "p-display-text",
              },
              {
                text: this.$tk("Common.Order.Receiver"),
                sortable: true,
                component: "p-display-location",
                config: { direction: "To" },
                align: "left",
              },
              {
                nested: [
                  {
                    text: this.$tk("Common.Order.RTI"),
                    component: "p-display-product",
                    aggregate: "p-display-product-images",
                    align: "left",
                    width: 220,
                  },
                  {
                    text: this.$tk("Common.Order.Ordered"),
                    value: "quantityOrdered",
                    component: "p-display-number",
                    aggregate: "p-display-sum",
                    width: 120,
                    align: "right",
                  },
                  {
                    text: this.$tk("Common.Order.Picked"),
                    value: "quantityPicked",
                    component: "p-display-number",
                    aggregate: "p-display-sum",
                    width: 120,
                    align: "right",
                  },
                ],
              },
              {
                text: this.$tk("Common.Order.CarType"),
                value: "truckTypeId",
                sortable: true,
                component: "p-display-text",
                align: "center",
              },
              {
                text: this.$tk("Common.Order.CarNo"),
                value: "shipmentCarNumber",
                sortable: true,
                component: "p-display-text",
                align: "center",
              },
              {
                text: this.$tk("Common.Order.CarNo2"),
                value: "shipmentCarNumber2",
                sortable: true,
                component: "p-display-text",
                align: "center",
              },
              {
                text: "Plomberingsnr.",
                value: "sealingId",
                component: "p-display-text",
              },
              {
                text: this.$tk("Common.Order.Comments"),
                value: "commentCount",
                component: "p-display-comments",
                width: 50,
                align: "center",
              },
              {
                text: this.$tk("Common.Order.Info"),
                component: "p-display-info",
                align: "left",
              },
              {
                text: this.$tk("Common.Order.Status"),
                component: "p-display-status",
                align: "center",
              },
            ]
          : [
              {
                text: this.$tk("Common.Order.OrderNo"),
                value: "id",
                sortable: true,
                component: "p-display-text",
                width: 110,
                align: "center",
                classes: "font-medium",
              },
              {
                text: this.$tk("Common.Order.PickupDate"),
                value: "dateSend",
                sortable: true,
                component: "p-display-date",
                width: 110,
                align: "center",
              },
              {
                text: this.$tk("Common.Order.Transporter"),
                value: "transporterName",
                sortable: true,
                component: "p-display-text",
              },
              {
                text: this.$tk("Common.Order.Receiver"),
                sortable: true,
                component: "p-display-location",
                config: { direction: "To" },
                align: "left",
              },
              {
                nested: [
                  {
                    text: this.$tk("Common.Order.RTI"),
                    component: "p-display-product",
                    aggregate: "p-display-product-images",
                    align: "left",
                    width: 220,
                  },
                  {
                    text: this.$tk("Common.Order.Ordered"),
                    value: "quantityOrdered",
                    component: "p-display-number",
                    aggregate: "p-display-sum",
                    width: 120,
                    align: "right",
                  },
                  {
                    text: this.$tk("Common.Order.Picked"),
                    value: "quantityPicked",
                    component: "p-display-number",
                    aggregate: "p-display-sum",
                    width: 120,
                    align: "right",
                  },
                ],
              },
              {
                text: this.$tk("Common.Order.CarType"),
                value: "truckTypeId",
                sortable: true,
                component: "p-display-text",
                align: "center",
              },
              {
                text: this.$tk("Common.Order.CarNo"),
                value: "shipmentCarNumber",
                sortable: true,
                component: "p-display-text",
                align: "center",
              },
              {
                text: this.$tk("Common.Order.Customs"),
                component: "p-display-customs-clearance",
                align: "center",
              },
              {
                text: this.$tk("Common.Order.Comments"),
                value: "commentCount",
                component: "p-display-comments",
                width: 50,
                align: "center",
              },
              {
                text: this.$tk("Common.Order.Info"),
                component: "p-display-info",
                align: "left",
              },
              {
                text: this.$tk("Common.Order.Status"),
                component: "p-display-status",
                align: "center",
              },
            ],

      orderId: null,
      expandAll: true,
      filters: {
        term: { value: "" },
        sendLower: {
          value: format(sub(new Date(), { days: 7 }), "yyyy-MM-dd"),
        },
        sendUpper: {
          value: format(add(new Date(), { days: 3 }), "yyyy-MM-dd"),
        },
        statuses: [
          { id: 1, value: false },
          {
            id: [2, 10],
            value: true,
            label: this.$tk("Common.OrderStatus.ConfirmedWabaShort"),
          },
          { id: 4, value: true },
          { id: 6, value: true },
          {
            label: this.$tk("Common.OrderStatus.Received"),
            ids: [5, 17],
            value: false,
          },
        ],
      },
      orderBy: {
        field: "dateSend",
        descending: false,
      },
      editDialogVisible: false,
      editDialogStartTab: "",
    };
  },

  computed: {
    ...mapGetters(["location"]),
  },

  methods: {
    onClick({ order, component }) {
      if (order.interpoolingCompany) return;

      this.orderId = order.id;
      this.editDialogStartTab =
        component === "p-display-comments" ? "comments" : "";
      this.editDialogVisible = true;
    },

    onClose() {
      this.orderId = null;
      this.editDialogVisible = false;

      this.$refs.ordergrid.getOrders();
    },
  },

  async created() {
    if (this.id) {
      const order = await http.get("Order", { params: { id: this.id } });
      this.onClick({ order });
    }
  },
};
</script>
